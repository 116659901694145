import React from 'react';
import { Router, Location } from '@reach/router';
import Home from 'templates/Home';
import WhatWeDoPage from 'templates/whatWeDo';
import WhoWeAre from 'templates/whoWeAre';
import ContactUs from 'templates/contactUs';
import PressMedia from 'templates/pressMedia';
import Impact from 'templates/impact';
import Investors from 'templates/investors';


const App = () => (
  <Location>
    {({ location, navigate }) => (
      <Router
        location={location}
        navigate={navigate}
        className="router"
      >
        <Home path="/" />
        <WhatWeDoPage path="/what-we-do" />
        <WhoWeAre path="/who-we-are" />
        <ContactUs path="/contact-us" />
        <PressMedia path="/press-media" />
        <Impact path="/impact" />
        <Investors path="/investors" />
      </Router>
    )}
  </Location>
);

export default App;
